@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer tailwind-base, primereact, tailwind-utilities; */

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
/* @import 'primereact/resources/themes/lara-light-blue/theme.css' layer(primereact); */

/* @layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
} */