* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.my-container {
    padding: 0 60px;
    margin: auto;
}

.main-slider-bg {
    background-repeat: no-repeat;
    background-size: cover !important;
}

.left div {
    align-content: center;
    align-items: center;
}

.right {
    align-content: center;
    align-items: center;
}

.footer {
    align-content: center;
    align-items: center;
}

.main_sil_content {
    text-align: center;
}

/* img hover  */

.effect-8 .info_box {
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    padding-top: 20%;
    background-color: rgba(0, 0, 0, .5);
}

.info_box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    /* background-color: rgba(0, 0, 0, .5); */
}

.img_box {
    position: relative;
    text-align: center;
    transition: 0.5s;
    border-radius: 5px;
    background: #F7F7F7;
}

img {
    height: 100%;
    object-fit: cover;
    transition: .5s;
}

.salepart {
    text-align: center;
}

.colletion-img {
    background: url("./image/collection-part-im-1.jpg");
    background-position: bottom right;
    background-repeat: no-repeat;
}

.bg-img {
    background: url('./image/Women\ Clothing-1.jpg');
    background-position: center top;
    height: 100%;
    height: 100%;
}

.bg-img-1 {
    background: url('./image/Mens\ Clothing-1.png');
    background-position: center top;
}

.bg-img-2 {
    background: url('./image/Mobile-3.png');
    background-position: center bottom;
}

.bg-img-3 {
    background: url('./image/Mobile-3.png');
    background-position: center bottom;
}

.bg-img-4 {
    background: url('./image/watch-1.jpg');
    background-position: center top;
    height: 100%;
}

.bg-trans {
    background-color: #15c1d7;
    opacity: .9;
}

.bg-trans-1 {
    background-color: #8c6e63;
    opacity: .9
}

.bg-trans-2 {
    background-color: #0059a7;
    opacity: .9;
}

.bg-trans-3 {
    background-color: #5c6dbd;
    opacity: .9;
}

.bg-trans-4 {
    background-color: #009893;
    opacity: .9;
}


/*  */

/* .button {
    cursor: pointer;
    position: relative;
    font-size: 18px;
    color: #3264D1;
    border: 2px solid #DDDDDD;
    font-weight: 600;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
    overflow: hidden;
}
.button >span{
    color: gray;
}

.button::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    scale: 0;
    z-index: -1;
    background-color: black;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.button:hover::before {
    scale: 3;
}

.button:hover {
    color: white;
    scale: 1.1;
    box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.button:active {
    scale: 1;
} */

.button {
    --color: #0077ff;
    font-family: inherit;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    margin: 0 20px;
    font-size: 17px;
    z-index: 1;
    border: 2px solid #DDDDDD;
    border-radius: 6px;
    position: relative;
}

.button span {
    color: rgb(149, 149, 149);
}

.button::before {
    position: absolute;
    content: "";
    background: black;
    width: 1000px;
    height: 900px;
    z-index: -1;
}

.button:hover {
    color: white;
    border: none;
}

.button:before {
    top: 100%;
    left: 100%;
    transition: 0.5s all;
}

.button:hover::before {
    top: -30px;
    left: -30px;
}


/*  */